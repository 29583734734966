





































































import { defineComponent, onMounted, SetupContext, ref, reactive, computed } from '@vue/composition-api'
import axios from 'axios'
export default defineComponent({
  setup(_, ctx: SetupContext) {
    const passwordResetObserver = reactive({
      rightfulness: true,
      uuid: '',
      access_code: '',
    })
    const opt = ref('')
    const password = ref('')
    const passwordConfirm = ref('')
    const passwordResetComplite = ref(false)
    const rules = {
      password: [
        (v: string) => !!v || 'パスワードを入力してください',
        (v: string) => /^[A-Za-z0-9]*$/.test(v) || 'パスワードは半角英数字のみで入力してください',
        (v: string) => v.length >= 8 || 'パスワードは8文字以上で入力してください',
      ],
      passwordConfirm: [
        (v: string) => !!v || 'パスワードを入力してください',
        (v: string) => /^[A-Za-z0-9]*$/.test(v) || 'パスワードは半角英数字のみで入力してください',
        (v: string) => v.length >= 8 || 'パスワードは8文字以上で入力してください',
        (v: string) => v === password.value || 'パスワードが一致しません',
      ],
    }
    const showPassword = ref(false)
    const loading = ref(false)
    const errorMessage = ref('')
    const passowrdResetloading = ref(false)
    const passwordResetProcess = ref(false)

    const passwordResetDisable = computed(() => {
      if (!password.value || !passwordConfirm.value) {
        return true
      }
      if (password.value === passwordConfirm.value) {
        return false
      }
      return true
    })
    //OPT入力完了時の処理
    const onFinish = (rsp: string) => {
      loading.value = true
      if (rsp === passwordResetObserver.access_code.toString()) {
        setTimeout(() => {
          loading.value = false
          passwordResetProcess.value = true
        }, 3000)
      } else {
        errorMessage.value = '不正なアクセスコードです'
        loading.value = false
        return
      }
    }
    //パスワード再設定処理
    const passowrdResetEventHandler = async () => {
      passowrdResetloading.value = true
      const inputs = {
        uuid: passwordResetObserver.uuid,
        password: password.value,
        access_code: passwordResetObserver.access_code,
      }
      const { uri: url } = ctx.root.$appConfig.endpoints.passwordReset
      const result = await axios
        .post(url, inputs)
        .then(() => true)
        .catch(() => false)
        .finally(() => (passowrdResetloading.value = false))
      if (result) {
        passwordResetComplite.value = true
        setTimeout(() => {
          ctx.root.$router.push({ name: 'Login' })
        }, 5000)
        return
      }
    }
    onMounted(async () => {
      const { key } = ctx.root.$route.query || ''
      const { uri } = ctx.root.$appConfig.endpoints.passwordResetValidate
      const url = uri.replace(':uuid', key as string)
      const data = await axios
        .get(url)
        .then((res) => res.data)
        .catch(() => false)
      if (!data) {
        passwordResetObserver.rightfulness = false
        return
      }
      passwordResetObserver.uuid = data.uuid
      passwordResetObserver.access_code = data.access_code
    })
    return {
      passwordResetObserver,
      opt,
      loading,
      onFinish,
      errorMessage,
      passwordResetProcess,
      password,
      showPassword,
      rules,
      passowrdResetEventHandler,
      passwordConfirm,
      passwordResetDisable,
      passowrdResetloading,
      passwordResetComplite,
    }
  },
})
